

.image {
    position: absolute;
    margin-top: -16px;
    margin-left: 0px;
    width: 34px;
    height: 34px;
}
.countContainer {
    font-family: Arial;
    font-size: 17px;
    font-weight: bold;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    background: #c66946;
    margin-top: -9px;
    color: #FFFFFF;
    height: 21px;
    margin-left: 28px;
    padding-top: 1px;
    padding-left: 10px;
    padding-right: 8px;
}
.countContainer[status='OPEN'] {
    background: #f6ad12;
}
.countContainer[status='PROGRESS'] {
    background: #c66946;
}
.countContainer[status='DONE'] {
    background: #70a53e;
}