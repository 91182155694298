:root {

  --venox-backgroundN0: #7c7c7c;
  --venox-background0: #C0C0C0;
  --venox-background1: #e0edfa;
  --venox-background2: #F0F0F0;
  --venox-background3: #FFFFFF;
  --venox-hoover: #1FA5E2;
}
.App {
  color: #7c7c7c;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  max-width: 80.25rem;
  margin-left: auto;
  margin-right: auto;
}
.wrapper:after {
  content: "";
  display: table;
  clear: both;
}


.button {
  margin: 2px 2px 12px 2px;
  display: inline-block;
  width: 42px;
  height: 42px;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 90px;
  padding: 10px 10px 10px 10px;
  font-size: 18px;
  transition: visibility 0.3s linear, background-color 500ms linear;
}

.button:hover {
  background: #01b079;
  color: #FFFFFF;
}
