.filterIcon {
    padding: 2px;
    cursor: pointer;
}
.filterIcon:hover {
     color: var(--venox-hoover);
 }


.firstRow {
    background: #FFFFFF;
    cursor: pointer;
}
.firstRow:hover {
    background: var(--venox-hoover);
    color: #FFFFFF;
}


.selectedRow {
    background: var(--venox-hoover);
    cursor: pointer;
    color: #FFFFFF;
}


.tableCellCreate {
    background: var(--venox-background1);
    text-align: center;
    padding: 5px;
    border-right: solid 3px var(--venox-background1);
    border-bottom: solid 3px var(--venox-background1);
}
.tableCellCreate:hover {
    cursor: pointer;
    background: #FFFFFF;
}

.status {
    min-width: 5px;
    width: 5px;
}

.status[status='IN_PROGRESS']{
    background: #ffa137;
}

.status[status='DONE']{
    background: #b3cb18;
}

.tableHeader {
    font-weight: bold;
    background: var(--venox-background1);
    text-align: left;
    padding: 15px 5px 15px 5px;
    border-right: solid 1px var(--venox-background1);
    border-bottom: solid 1px var(--venox-background1);
}
.tableCell {
    text-align: left;
    padding: 15px 5px 15px 5px;

    border-top:  solid 1px var(--venox-background1);
    border-bottom:  solid 1px var(--venox-background1);
}


