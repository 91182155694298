.markerRow{
    cursor: pointer;
}
.markerRowSelected{
    color: #FFFFFF;
    background: #01d997;
}
.markerRow:hover{
    color: #FFFFFF;
    background: #01d997;
}


.status {
    margin: 2px;
    display: inline-block;
    width: 42px;
    height: 42px;
    cursor: pointer;
    background: #01b079;
    color: #FFFFFF;
    border-radius: 90px;
    padding: 10px 10px 10px 10px;
    font-size: 18px;
    margin-bottom: 12px;
    transition: visibility 0.3s linear, background-color 500ms linear;
}


.attachment {
    color: blue;
}

.attachment:hover {
    text-decoration: underline;
    cursor: pointer;
}