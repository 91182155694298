
.circleChildContainer {
    margin-top: -15px;
    position: absolute;
    border-radius: 45px;
    background: #90CCFB;
    width: 33px;
    height: 33px;
}
.image {
    position: absolute;
    margin-top: -16px;
    margin-left: 0px;
    width: 34px;
    height: 34px;
}
.countContainer {
    font-family: Arial;
    font-size: 17px;
    font-weight: bold;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    background: #c33c39;
    margin-top: -9px;
    color: #FFFFFF;
    height: 21px;
    margin-left: 28px;
    padding-top: 1px;
    padding-left: 10px;
    padding-right: 8px;
}