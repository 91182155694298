
.inputTextContainer {

}
.inputTextContainer:hover {
    outline: dashed 1px #3579F6;
}

.inputTextEditContainer {
    outline: dashed 1px #3579F6;
    color: #000000 !important;
    background: #FFFFFF !important;
}

.inputText {
    margin-left: -2px;
    margin-right: -2px;
    margin-top: -2px;
    margin-bottom: -6px;
    font-family: Montserrat;
    color: #4A5056;
    border: solid 0px #FFFFFF;
    font-size: 16px;
    background: transparent;
    resize: none;
    outline: dashed 0px #3579F6;
}
.inputText:hover {
}
.inputText:focus {
}