

.formRow {
    background: #FDFDFD;
}
.formRow:hover {
    background: #afd1ff;
}

.formKeyRow:hover {
    background: #93c2ff;
}