
.attachment {
    color: blue;
    cursor: pointer;
    text-align: left;
    padding-left: 2px;
}

.attachment:hover {
    color: red;
    text-decoration: line-through;
}