

.root {
    height: 50vh;
    min-height: 400px;
    display: flex;
    justify-content: center;
}

.panel {
    align-self: center;
    background: radial-gradient(#dfdfdf, #efefef, #dfdfdf);
    border-radius: 18px; padding: 10px;
    width: 320px;
}